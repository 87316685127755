import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import RootElement from '../app/Root';
import {setRef} from '@peoplenet/vi-components-ui/src/app/configuration/configurationAction';
import {popQueryParameter} from '@peoplenet/vi-components-ui/src/app/historyUtil';

export class BetaWrapper extends React.Component {
  componentWillMount() {
    const ref = popQueryParameter('ref') || localStorage.getItem('systemId');
    const token = popQueryParameter('token');
    const pfm2SessionId = popQueryParameter('pfm2SessionId');
    const customerId = popQueryParameter('customerId') || popQueryParameter('cid');
    if (ref) {
      if (ref === 'pfm' || ref === 'togs') {
        this.props.setRef({ref});
        const systemId = {pfm: 'peoplenet', togs: 'togs'};
        const expiresIn = moment().valueOf();
        localStorage.setItem('expires_at', JSON.stringify((expiresIn * 1000) + new Date().getTime()));
        localStorage.setItem('systemId', systemId[ref]);
        if (token) {
          localStorage.setItem('togstoken', token);
        }

        if (pfm2SessionId) {
          localStorage.setItem('pfm2SessionId_customerId', `${pfm2SessionId}_${customerId}`);
        }
      } else if (ref === 'ttl') {
        localStorage.setItem('ttl_customerId', `${customerId}`);
      }
    }
  }

  render() {
    return (
      <RootElement />
    );
  }
}

export const mapStateToProps = state => {
  return {...state};
};

export const mapDispatcherToProps = dispatcher => ({
  setRef: ref => dispatcher(setRef(ref))
});

export default connect(mapStateToProps, mapDispatcherToProps)(BetaWrapper);
